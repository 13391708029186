enum State {
  START = "Start",
  WAITING_FOR_QUEUE = "Wait for queue",
  FOUND_BUT_DELAYING = "Found but delaying",
  JOINING_QUEUE = "Join queue",
  SETTING_SECRET_WORD = "Set secret word",
  WAIT_FOR_OPPONENT_SECRET_WORD = "Wait for opponent's secret word",
  GUESSING_WORDS = "Guess words",
  WAITING_FOR_REMATCH = "Wait for rematch",
}

export default State;
