import { CircularProgress, Typography } from "@mui/material";
import { callCloudFunction } from "firebase-react-utils/build/functions/FunctionsUtil";
import { useCurrentUser } from "firebase-react-utils/build/Hooks";
import { useData } from "firebase-react-utils/build/realtime/RealtimeHooks";
import { getDatabase } from "firebase-react-utils/build/realtime/RealtimeStore";
import { Dispatch, SetStateAction, useMemo } from "react";
import { useAsyncEffect, useDelayedAction } from "../utils/hook-utils";
import State from "./State";

type Props = {
  setState: Dispatch<SetStateAction<State>>;
  matchId: string;
  delay: number;
  setMatchId: Dispatch<SetStateAction<string>>;
};

function WaitingForRematchView({
  setState,
  matchId,
  delay,
  setMatchId,
}: Props) {
  const matchState = useData(getDatabase(), [
    "matches",
    matchId,
    "public",
    "state",
  ]);

  const rematchInviteId = matchState?.rematchInviteId;
  const winner = matchState?.winner;

  const botUser = useCurrentUser();
  const botId = useMemo(() => botUser?.uid, [botUser]);

  useAsyncEffect(async () => {
    if (rematchInviteId == null) {
      return;
    }

    const { data } = await callCloudFunction("joinInvite", {
      inviteId: rematchInviteId,
    });
    const { matchId } = data;
    setMatchId(matchId);
    setState(State.SETTING_SECRET_WORD);
  }, [rematchInviteId]);

  const [timeRemaining, setRunning] = useDelayedAction(delay, async () => {
    setRunning(false);
    setMatchId("");
    setState(State.WAITING_FOR_QUEUE);
  });

  return (
    <section className="w-full h-full flex flex-col justify-center items-center">
      <Typography variant="caption" className="text-center">
        Match id: {matchId}
      </Typography>
      <CircularProgress className="m-4" />
      <Typography variant="h4" className="text-center">
        Bot {winner === botId ? "won" : "lost"}! Waiting for rematch...
      </Typography>
      <Typography variant="h4" className="text-center">
        Resetting in
      </Typography>
      <Typography variant="h2" className="text-center">
        {timeRemaining / 1000}
      </Typography>
    </section>
  );
}

export default WaitingForRematchView;
