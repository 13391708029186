export enum TimeConfigType {
  JOIN_DELAY = "Join delay",
  ENTER_SECRET_WORD_DURATION = "Duration to enter secret word",
  WAIT_FOR_OPPONENT_SECRET_WORD_TIMEOUT = "Wait for opponent's secret word timeout",
  TYPING_DURATION = "Duration to type guess",
  REMATCH_TIMEOUT = "Rematch timeout",
}

export type TimeConfigValue = {
  value: number;
  min: number;
  max: number;
  render: (value: number) => string;
};

export class TimeConfig {
  static of(
    value: number,
    min: number = 0,
    max: number,
    render: (value: number) => string = (value) => value.toString()
  ): TimeConfigValue {
    return {
      value,
      min,
      max,
      render,
    };
  }
}

export type Config = {
  times: {
    [type in TimeConfigType]: TimeConfigValue;
  };

  mistakes: number;
};
