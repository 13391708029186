import { CircularProgress, Typography } from "@mui/material";
import { callCloudFunction } from "firebase-react-utils/build/functions/FunctionsUtil";
import { useCurrentUser } from "firebase-react-utils/build/Hooks";
import { useData } from "firebase-react-utils/build/realtime/RealtimeHooks";
import { getDatabase } from "firebase-react-utils/build/realtime/RealtimeStore";
import {
  Dispatch,
  Fragment,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDelayedAction } from "../utils/hook-utils";
import wordList from "../word-list.json";
import State from "./State";

type Props = {
  setState: Dispatch<SetStateAction<State>>;
  matchId: string;
  mistakes: number;
  typingDuration: number;
  getGuessingDelay: (
    possibleWordsCount: number,
    totalWordCount: number,
    guesses: any
  ) => number;
};

function getPossibleWords(guessesList: any, mistakes: number): string[] {
  return Object.keys(wordList).filter((word) =>
    Object.keys(guessesList)
      .map((key) => guessesList[key].letters)
      .every((guesses) =>
        guesses.every((guess: any, i: number) => {
          switch (guess.state) {
            case "CORRECT":
              return word[i] === guess.letter;
            case "EXISTS":
              return word[i] !== guess.letter && word.includes(guess.letter);
            case "ABSENT":
              return (
                guesses.filter((other: any) => other.letter === guess.letter)
                  .length > 1 || !word.includes(guess.letter)
              );
            default:
              throw new Error(`Unexpected state: ${guess.state}`);
          }
        })
      )
  );
}

const MAX_GUESSES = 6;

function GuessingWordsView({
  setState,
  matchId,
  mistakes,
  typingDuration,
  getGuessingDelay,
}: Props) {
  const matchData = useData(getDatabase(), ["matches", matchId, "public"]);

  const botUser = useCurrentUser();
  const botId = useMemo(() => botUser?.uid, [botUser]);
  const botGuesses = useData(getDatabase(), [
    "matches",
    matchId,
    "public",
    "players",
    botId,
    "guesses",
  ]);

  const [opponentId, setOpponentId] = useState<string>();
  useEffect(() => {
    if (matchData == null || botId == null) {
      return;
    }

    const otherId = Object.keys(matchData.players).find(
      (userId) => userId !== botId
    )!!;

    setOpponentId(otherId);
  }, [matchData, botId]);
  const opponentGuesses = useData(getDatabase(), [
    "matches",
    matchId,
    "public",
    "players",
    opponentId,
    "guesses",
  ]);

  const firstGuesses = ["STEAL", "CRANE", "SNAKE", "APPLE", "ABOUT", "CROWN"];
  const firstGuess =
    firstGuesses[Math.floor(Math.random() * firstGuesses.length)];
  const [nextGuess, setNextGuess] = useState(firstGuess);
  const [delay, setDelay] = useState(typingDuration);
  const [timeRemaining, setRunning] = useDelayedAction(delay, async () => {
    await callCloudFunction("guessWord", {
      matchId,
      guess: nextGuess,
    });
  });

  useEffect(() => {
    if (botGuesses == null) {
      return;
    }

    if (Object.keys(botGuesses).length === MAX_GUESSES) {
      setRunning(false);
      return;
    }

    const possibleWords = getPossibleWords(botGuesses, mistakes);
    const word =
      possibleWords[Math.floor(Math.random() * possibleWords.length)];

    const delay =
      typingDuration +
      getGuessingDelay(
        possibleWords.length,
        Object.keys(wordList).length,
        botGuesses
      );
    console.log(delay, word, possibleWords);
    setDelay(delay);
    setNextGuess(word);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [botGuesses]);

  useEffect(() => {
    if (matchData == null) {
      return;
    }

    if (matchData.state.phase === "WON") {
      setRunning(false);
      setState(State.WAITING_FOR_REMATCH);
    }
  }, [matchData, setRunning, setState]);

  function renderGuesses(guesses: any) {
    if (guesses == null) {
      return <div className="flex-1"></div>;
    }
    // bg-state-correct bg-state-exists bg-state-absent
    return (
      <div className="flex flex-col flex-1 space-y-2 mt-4">
        {Object.keys(guesses)
          .map((key) => guesses[key].letters)
          .map((guesses, i) => (
            <div key={i} className="flex flex-row justify-evenly space-x-2">
              {guesses.map((guess: any, i: number) => (
                <div
                  key={i}
                  className={`flex justify-center items-center w-full uppercase p-1 md:p-4 text-md md:text-3xl rounded-md bg-state-${guess.state.toLowerCase()}`}
                >
                  {guess.letter}
                </div>
              ))}
            </div>
          ))}
      </div>
    );
  }

  return (
    <section className="w-full h-full flex flex-col justify-center items-center">
      <Typography variant="caption" className="text-center">
        Match id: {matchId}
      </Typography>
      {Object.keys(botGuesses ?? {}).length === MAX_GUESSES ? (
        <Fragment>
          <CircularProgress className="m-4" />
          <Typography variant="h6" className="text-center">
            Failed to guess the opponent's word. Waiting for opponent to
            finish...
          </Typography>
        </Fragment>
      ) : (
        <Fragment>
          <Typography variant="h6" className="text-center">
            Guessing {nextGuess} in
          </Typography>
          <Typography variant="h2" className="text-center">
            {timeRemaining / 1000}
          </Typography>
        </Fragment>
      )}
      <div className="w-full h-full flex flex-row space-x-16">
        {renderGuesses(botGuesses)}
        {renderGuesses(opponentGuesses)}
      </div>
    </section>
  );
}

export default GuessingWordsView;
