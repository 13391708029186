import { Chip, useMediaQuery, useTheme } from "@mui/material";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import { useState } from "react";
import { Config, TimeConfigType } from "./Config";
import FoundButDelayingView from "./FoundButDelayingView";
import GuessingWordsView from "./GuessingWordsView";
import JoiningQueueView from "./JoiningQueueView";
import SettingSecretWordView from "./SettingSecretWordView";
import StartView from "./StartView";
import State from "./State";
import WaitForOpponentSecretWordView from "./WaitForOpponentSecretWordView";
import WaitingForQueueView from "./WaitingForQueueView";
import WaitingForRematchView from "./WaitingForRematchView";

const MAX_GUESSES = 6;

function BotView() {
  const [state, setState] = useState(State.START);
  const [matchId, setMatchId] = useState<string>("");
  const [config, setConfig] = useState<Config>(null as any as Config);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  function renderStepper() {
    return matches ? (
      <Stepper
        activeStep={Object.values(State).indexOf(state)}
        alternativeLabel
      >
        {Object.values(State).map((state) => (
          <Step key={state}>
            <StepLabel>{state}</StepLabel>
          </Step>
        ))}
      </Stepper>
    ) : (
      <div className="flex flex-col items-center">
        <Chip label={state} size="small" className="m-2" />
        <Stepper
          activeStep={Object.values(State).indexOf(state)}
          alternativeLabel
        >
          {Object.values(State).map((state) => (
            <Step key={state}>
              <StepLabel />
            </Step>
          ))}
        </Stepper>
      </div>
    );
  }

  function renderState(state: State) {
    switch (state) {
      case State.START:
        return <StartView setState={setState} setConfig={setConfig} />;
      case State.WAITING_FOR_QUEUE:
        return <WaitingForQueueView setState={setState} resetUser />;
      case State.FOUND_BUT_DELAYING:
        return (
          <FoundButDelayingView
            setState={setState}
            delay={config.times[TimeConfigType.JOIN_DELAY].value}
          />
        );
      case State.JOINING_QUEUE:
        return <JoiningQueueView setState={setState} setMatchId={setMatchId} />;
      case State.SETTING_SECRET_WORD:
        return (
          <SettingSecretWordView
            setState={setState}
            delay={
              config.times[TimeConfigType.ENTER_SECRET_WORD_DURATION].value
            }
            matchId={matchId}
          />
        );
      case State.WAIT_FOR_OPPONENT_SECRET_WORD:
        return (
          <WaitForOpponentSecretWordView
            setState={setState}
            matchId={matchId}
            timeout={
              config.times[TimeConfigType.WAIT_FOR_OPPONENT_SECRET_WORD_TIMEOUT]
                .value
            }
          />
        );
      case State.GUESSING_WORDS:
        return (
          <GuessingWordsView
            setState={setState}
            matchId={matchId}
            mistakes={config.mistakes}
            typingDuration={config.times[TimeConfigType.TYPING_DURATION].value}
            getGuessingDelay={(
              possibleWordsCount: number,
              totalWordCount: number,
              guesses: any
            ) => {
              if (possibleWordsCount < 5) {
                return 3000;
              }

              return Math.floor(
                Math.sin((1 - possibleWordsCount / totalWordCount) * Math.PI) *
                  30000 +
                  Math.sin(
                    (1 - Object.keys(guesses).length / MAX_GUESSES) * Math.PI
                  ) *
                    20000
              );
            }}
          />
        );
      case State.WAITING_FOR_REMATCH:
        return (
          <WaitingForRematchView
            setState={setState}
            matchId={matchId}
            delay={config.times[TimeConfigType.REMATCH_TIMEOUT].value}
            setMatchId={setMatchId}
          />
        );
    }
  }

  return (
    <div className="w-full h-full flex flex-col flex-row items-center">
      <div className="m-2 md:m-8">
        {renderStepper()}
        {renderState(state)}
      </div>
    </div>
  );
}

export default BotView;
