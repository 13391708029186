import { initialize } from "firebase-react-utils/build/Firebase";

const firebaseProdConfig = {
  apiKey: "AIzaSyBr0LI7aWgCNo26xumMYKQhpdbm2e5sWtk",
  authDomain: "swordle-3d82a.firebaseapp.com",
  databaseURL: "https://swordle-3d82a-default-rtdb.firebaseio.com",
  projectId: "swordle-3d82a",
  storageBucket: "swordle-3d82a.appspot.com",
  messagingSenderId: "579192906856",
  appId: "1:579192906856:web:03de4c40c6a804bfdfc7dd",
  measurementId: "G-L7N7566ECP",
};
const firebaseStagingConfig = {
  apiKey: "AIzaSyBvzAbxDOqfqEtNM3-R9tbO30F1S0iTKeI",
  authDomain: "swordle-staging.firebaseapp.com",
  databaseURL: "https://swordle-staging-default-rtdb.firebaseio.com",
  projectId: "swordle-staging",
  storageBucket: "swordle-staging.appspot.com",
  messagingSenderId: "1003292841946",
  appId: "1:1003292841946:web:498323f0e3da45d8f452e3",
  measurementId: "G-XXP1HF6JF5",
};

function initializeFirebaseApp() {
  return initialize(
    // process.env.REACT_APP_ENV === "prod"
    // ?
    firebaseProdConfig
    // : firebaseStagingConfig
  );
}

export default initializeFirebaseApp;
