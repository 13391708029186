import { ThemeProvider } from "@mui/material/styles";
import initializeFirebaseApp from "./firebaseApp";
import theme from "./theme";
import BotView from "./views/BotView";

initializeFirebaseApp();

function App() {
  return (
    <ThemeProvider theme={theme}>
      <BotView />
    </ThemeProvider>
  );
}

export default App;
