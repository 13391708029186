import { Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { callCloudFunction } from "firebase-react-utils/build/functions/FunctionsUtil";
import { useData } from "firebase-react-utils/build/realtime/RealtimeHooks";
import { getDatabase } from "firebase-react-utils/build/realtime/RealtimeStore";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  signInAnonymously,
  deleteCurrentUser,
} from "firebase-react-utils/build/Firebase";
import State from "./State";
import { useAsyncEffect } from "../utils/hook-utils";

type Props = {
  setState: Dispatch<SetStateAction<State>>;
  resetUser: boolean;
};

function getFirstPending(queue: any) {
  if (queue == null) {
    return null;
  }

  return Object.keys(queue)
    .map((k) => queue[k])
    .filter((e: any) => e.status === "PENDING")[0];
}

function WaitingForQueueView({ setState, resetUser }: Props) {
  const [isSignedIn, setSignedIn] = useState(false);
  useAsyncEffect(async () => {
    if (isSignedIn) {
      return;
    }

    if (resetUser) {
      await signInAnonymously();
      await deleteCurrentUser();
    }

    const user = await signInAnonymously();
    await callCloudFunction("addUser");
    console.log("Current user", user);
    setSignedIn(true);
  }, [isSignedIn]);

  const queue = useData(getDatabase(), ["match_queue"]);

  useEffect(() => {
    if (!isSignedIn) {
      return;
    }

    const pending = getFirstPending(queue);
    if (pending == null) {
      return;
    }

    setState(State.FOUND_BUT_DELAYING);
  }, [isSignedIn, queue, setState]);

  return (
    <section className="w-full h-full flex flex-col justify-center items-center">
      <CircularProgress className="m-4" />
      <Typography variant="h5" className="text-center">
        Waiting for player to join the queue...
      </Typography>
    </section>
  );
}

export default WaitingForQueueView;
