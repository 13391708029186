import { Typography } from "@mui/material";
import { useData } from "firebase-react-utils/build/realtime/RealtimeHooks";
import { getDatabase } from "firebase-react-utils/build/realtime/RealtimeStore";
import { Dispatch, SetStateAction, useEffect, useMemo } from "react";
import { useDelayedAction } from "../utils/hook-utils";
import State from "./State";

type Props = {
  setState: Dispatch<SetStateAction<State>>;
  delay: number;
};

function getFirstPending(queue: any) {
  if (queue == null) {
    return null;
  }

  return Object.keys(queue)
    .map((k) => queue[k])
    .filter((e: any) => e.status === "PENDING")[0];
}

function FoundButDelayingView({ setState, delay }: Props) {
  const queue = useData(getDatabase(), ["match_queue"]);
  const pending = useMemo(() => getFirstPending(queue), [queue]);

  const [timeRemaining, setRunning] = useDelayedAction(delay, async () => {
    setRunning(false);
    setState(State.JOINING_QUEUE);
  });

  useEffect(() => {
    if (queue == null) {
      return;
    }

    if (pending == null) {
      setRunning(false);
      setState(State.WAITING_FOR_QUEUE);
      return;
    }
  }, [queue, pending, setRunning, setState]);

  return (
    <section className="w-full h-full flex flex-col justify-center items-center">
      <Typography variant="caption" className="text-center">
        Queue id: {pending?.id}
      </Typography>
      <Typography variant="h6" className="text-center">
        Someone joined the queue! Joining in
      </Typography>
      <Typography variant="h2" className="text-center">
        {timeRemaining / 1000}
      </Typography>
    </section>
  );
}

export default FoundButDelayingView;
