import {
  DependencyList,
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";

export function useAsyncEffect(
  effect: () => Promise<any>,
  deps?: DependencyList
) {
  useEffect(() => {
    const promise = effect();
    return () => {
      promise.then((cleanup) => {
        if (cleanup == null) {
          return;
        }
        cleanup();
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}

export function useForceUpdate(): () => void {
  return useReducer(() => ({}), {})[1] as () => void;
}

export function useDelayedAction(
  delay: number,
  action: () => Promise<void>,
  increment: number = 10
): [timeRemaining: number, setRunning: Dispatch<SetStateAction<boolean>>] {
  const [running, setRunning] = useState(true);

  const [lastActivatedTime, setLastActivateTime] = useState(Date.now());
  const [currentTime, setCurrentTime] = useState(Date.now());

  const timeRemaining = useMemo(() => {
    const elapsedTime = currentTime - lastActivatedTime;
    return Math.max(delay - elapsedTime, 0);
  }, [delay, currentTime, lastActivatedTime]);

  useAsyncEffect(async () => {
    if (!running) {
      return;
    }

    if (timeRemaining === 0) {
      await action();
      setLastActivateTime(Date.now());
      return;
    }

    const timeout = setTimeout(() => setCurrentTime(Date.now()), increment);
    return () => {
      clearTimeout(timeout);
    };
  }, [running, timeRemaining]);

  return [timeRemaining, setRunning];
}
