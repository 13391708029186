import { green, pink } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

export default createTheme({
  palette: {
    mode: "dark",
    primary: pink,
    secondary: green,
  },
  typography: {
    fontFamily: "Arial",
    button: {
      textTransform: "none",
      fontSize: 16,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          padding: "4px 24px 4px 24px",
        },
      },
    },
  },
});
