import { Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { callCloudFunction } from "firebase-react-utils/build/functions/FunctionsUtil";
import { useData } from "firebase-react-utils/build/realtime/RealtimeHooks";
import {
  getDatabase,
  getData,
} from "firebase-react-utils/build/realtime/RealtimeStore";
import { Dispatch, SetStateAction, useCallback, useState } from "react";
import { useAsyncEffect } from "../utils/hook-utils";
import State from "./State";

type Props = {
  setState: Dispatch<SetStateAction<State>>;
  setMatchId: Dispatch<SetStateAction<string>>;
};

function JoiningQueueView({ setState, setMatchId }: Props) {
  const [matchQueueId, setMatchQueueId] = useState<string>();
  const matchQueueData = useData(getDatabase(), ["match_queue", matchQueueId]);

  const joinQueue = useCallback(async () => {
    const { data } = await callCloudFunction("findMatch");
    return data;
  }, []);

  const leaveQueue = useCallback(async () => {
    const { data } = await callCloudFunction("leaveQueue");
    return data;
  }, []);

  useAsyncEffect(async () => {
    const { matchQueueId } = await joinQueue();
    setMatchQueueId(matchQueueId);

    return () => {
      leaveQueue();
    };
  }, []);

  useAsyncEffect(async () => {
    if (matchQueueData == null) {
      return;
    }

    switch (matchQueueData.status) {
      case "MATCHED": {
        const matchQueueData = await getData(getDatabase(), [
          "match_queue",
          matchQueueId,
        ]);
        setMatchId(matchQueueData.matchId);
        setState(State.SETTING_SECRET_WORD);
        break;
      }
      case "PENDING": {
        await leaveQueue();
        setState(State.WAITING_FOR_QUEUE);
        break;
      }
    }
  }, [matchQueueData]);

  return (
    <section className="w-full h-full flex flex-col justify-center items-center">
      <CircularProgress className="m-4" />
      <Typography variant="h4" className="text-center">
        Joining the queue...
      </Typography>
    </section>
  );
}

export default JoiningQueueView;
