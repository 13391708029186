import { Button, TextField, Typography } from "@mui/material";
import {
  ChangeEvent,
  Dispatch,
  Fragment,
  SetStateAction,
  useState,
} from "react";
import { useForceUpdate } from "../utils/hook-utils";
import { Config, TimeConfig, TimeConfigType, TimeConfigValue } from "./Config";
import State from "./State";

type Props = {
  setState: Dispatch<SetStateAction<State>>;
  setConfig: Dispatch<SetStateAction<Config>>;
};

function StartView({ setState, setConfig: setParentConfig }: Props) {
  const forceUpdate = useForceUpdate();
  const [config, setConfig] = useState({
    times: {
      [TimeConfigType.JOIN_DELAY]: TimeConfig.of(5000, 0, 10000),
      [TimeConfigType.ENTER_SECRET_WORD_DURATION]: TimeConfig.of(
        10000,
        0,
        30000
      ),
      [TimeConfigType.WAIT_FOR_OPPONENT_SECRET_WORD_TIMEOUT]: TimeConfig.of(
        60000,
        0,
        300000
      ),
      [TimeConfigType.TYPING_DURATION]: TimeConfig.of(2000, 0, 10000),
      [TimeConfigType.REMATCH_TIMEOUT]: TimeConfig.of(30000, 0, 300000),
    },
    mistakes: 0.2,
  });

  function renderSlider(
    timeConfigType: TimeConfigType,
    timeConfig: TimeConfigValue
  ) {
    return (
      <div className="flex flex-row justify-between items-center space-x-8 p-4 bg-slate-700 rounded-md hover:brightness-150">
        <Typography className="flex-[3]">{timeConfigType}</Typography>
        <TextField
          className="flex-1 text-right"
          value={timeConfig.value}
          size="small"
          inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            timeConfig.value = +e.target.value;
            setConfig(config);
            forceUpdate();
          }}
        />
      </div>
    );
  }

  return (
    <section className="w-full h-full flex flex-col justify-center items-center space-y-8">
      <Typography variant="h3" className="text-center">
        Friendle Bot
      </Typography>
      <div className="flex flex-col items-stretch md:space-y-4 bg-slate-700 rounded-md">
        {keys<TimeConfigType>(config.times).map(
          (timeConfigType: TimeConfigType, i: number) => (
            <Fragment key={i}>
              {renderSlider(timeConfigType, config.times[timeConfigType])}
            </Fragment>
          )
        )}
      </div>
      <Button
        variant="contained"
        onClick={() => {
          setParentConfig(config);
          setState(State.WAITING_FOR_QUEUE);
        }}
      >
        Start
      </Button>
    </section>
  );
}

function keys<T>(o: any): T[] {
  return Object.keys(o).map((s) => s as any as T);
}

export default StartView;
