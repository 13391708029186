import { CircularProgress, Typography } from "@mui/material";
import { useData } from "firebase-react-utils/build/realtime/RealtimeHooks";
import { getDatabase } from "firebase-react-utils/build/realtime/RealtimeStore";
import { Dispatch, SetStateAction, useEffect } from "react";
import { useDelayedAction } from "../utils/hook-utils";
import State from "./State";

type Props = {
  setState: Dispatch<SetStateAction<State>>;
  matchId: string;
  timeout: number;
};

function WaitForOpponentSecretWordView({ setState, matchId, timeout }: Props) {
  const matchData = useData(getDatabase(), [
    "matches",
    matchId,
    "public",
    "state",
  ]);

  useEffect(() => {
    if (matchData == null) {
      return;
    }

    if (matchData.phase !== "STARTED") {
      return;
    }

    setState(State.GUESSING_WORDS);
  }, [matchData, setState]);

  const [timeRemaining, setRunning] = useDelayedAction(timeout, async () => {
    setRunning(false);
    setState(State.WAITING_FOR_QUEUE);
  });

  return (
    <section className="w-full h-full flex flex-col justify-center items-center">
      <Typography variant="caption" className="text-center">
        Match id: {matchId}
      </Typography>
      <CircularProgress className="m-4" />
      <Typography variant="h4" className="text-center">
        Waiting for opponent to enter their secret word...
      </Typography>
      <Typography variant="h4" className="text-center">
        Resetting in
      </Typography>
      <Typography variant="h2" className="text-center">
        {timeRemaining / 1000}
      </Typography>
    </section>
  );
}

export default WaitForOpponentSecretWordView;
