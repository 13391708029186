import { Typography } from "@mui/material";
import { callCloudFunction } from "firebase-react-utils/build/functions/FunctionsUtil";
import { Dispatch, SetStateAction, useMemo } from "react";
import { useDelayedAction } from "../utils/hook-utils";
import wordList from "../word-list.json";
import State from "./State";

type Props = {
  setState: Dispatch<SetStateAction<State>>;
  delay: number;
  matchId: string;
};

function getRandomWord() {
  const words = Object.keys(wordList);
  return words[Math.floor(Math.random() * words.length)];
}

function SettingSecretWordView({ setState, delay, matchId }: Props) {
  const secretWord = useMemo(() => getRandomWord(), []);

  const [timeRemaining, setRunning] = useDelayedAction(delay, async () => {
    if (matchId == null) {
      return;
    }

    await callCloudFunction("setTargetWord", {
      matchId,
      secretWord,
    });
    setRunning(false);
    setState(State.WAIT_FOR_OPPONENT_SECRET_WORD);
  });

  return (
    <section className="w-full h-full flex flex-col justify-center items-center">
      <Typography variant="caption" className="text-center">
        Match id: {matchId}
      </Typography>
      <Typography variant="h6" className="text-center">
        Setting <code>{secretWord}</code> as secret word in
      </Typography>
      <Typography variant="h2" className="text-center">
        {timeRemaining / 1000}
      </Typography>
    </section>
  );
}

export default SettingSecretWordView;
